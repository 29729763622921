// import * as Ladda from 'ladda';
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

import setupGeoLocation from './utils/geolocation';
import setupLoginMenu from './utils/login-menu';
import setupLanguageSwitcher from './utils/language-switcher';

const bodyId = document.body.getAttribute('data-id');

setupLanguageSwitcher();
setupLoginMenu();

if (bodyId === 'index') {
//     only check for geolocation on home page
    setupGeoLocation();
}

// lazy loading background images
document.addEventListener('lazybeforeunveil', function(e){
    let bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});


