import userMessage from './user-message';

function geo_success(position) {
    window.location.href = `/search/?lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
}

function geo_error(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            userMessage('error', gettext('Sorry, you denied the request for Geolocation.'));
            break;
        case error.POSITION_UNAVAILABLE:
            userMessage('error', gettext('Sorry, geolocation information is unavailable.'));
            break;
        case error.TIMEOUT:
            userMessage('error', gettext('Sorry, the request to get your location timed out.'));
            break;
        case error.UNKNOWN_ERROR:
            userMessage('error', gettext('Sorry, an unknown error occurred.'));
            break;
    }
}

function getNearby() {
    const geo_options = {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
    };
    const wpid = navigator.geolocation.getCurrentPosition(geo_success, geo_error, geo_options);
}

function setupGeoLocation() {
    // check navigator does geolocation and 'view nearby' link is present (link only shown if IP is EU-based)
    if ('geolocation' in navigator && document.getElementById('getnearby')) {
        document.getElementById('getnearby').onclick = () => getNearby();
    }
}

export default setupGeoLocation;