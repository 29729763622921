let menuOpen = false;

function loginMenu() {
    const menuTrigger = document.getElementsByClassName('js-sub-toggle')[0];
    menuTrigger.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        menuTrigger.classList.toggle('js-open');
        document.body.classList.toggle('js-menu-open');
        menuOpen = !menuOpen;
        document.getElementsByClassName('js-sub-menu')[0].classList.toggle('js-invisible');
    };
    document.onclick = () => {
        if (menuOpen) {
            document.querySelector('.js-menu-open .js-sub-menu').classList.toggle('js-invisible');
            document.querySelector('.js-menu-open .js-sub-toggle').classList.toggle('js-open');
            document.body.classList.remove('js-menu-open');
            menuOpen = false;
        }
    };
}

export default loginMenu;