function userMessage(type, message, timeout) {
    'use strict';
    timeout = timeout || 4000;
    let newMessage = document.createElement('div');
    let classType = 'User-message--' + type;
    let messageContainer = document.getElementById('userMessages');

    if (messageContainer) {
        newMessage.classList.add('User-message', 'User-message--show', classType);
        newMessage.innerText = message;
        messageContainer.insertBefore(newMessage, messageContainer.childNodes[0]);

        // Set timer to remove message
        setTimeout(function () {
                newMessage.classList.add('User-message--hide');
                // When animation completes, remove
                setTimeout(function () {
                    newMessage.remove();
                }, 400);
            }, timeout
        );
    }
}

export default userMessage;